// extracted by mini-css-extract-plugin
export var welcomeBannerContainer = "index-module--welcomeBannerContainer--2ymqd";
export var goldSubsectionTitle = "index-module--goldSubsectionTitle--1swaw";
export var whiteSubsectionTitle = "index-module--whiteSubsectionTitle--2u5PC";
export var welcomeBannerSubtitle = "index-module--welcomeBannerSubtitle--1YqSb";
export var subtitle = "index-module--subtitle--3eOda";
export var centering = "index-module--centering--Z5Z9d";
export var white = "index-module--white--I2VFw";
export var whiteText = "index-module--whiteText--3FHMO";
export var gold = "index-module--gold--Xa0Hj";
export var flexContainerSpaceBetween = "index-module--flexContainerSpaceBetween--1fyLg";
export var flexContainerSpaceEvenly = "index-module--flexContainerSpaceEvenly--3Z6mx";
export var introCopy = "index-module--introCopy--3xoEF";
export var bioCopy = "index-module--bioCopy--2xnwK";
export var galleryImage = "index-module--galleryImage--3lqHO";
export var instagramIcon = "index-module--instagramIcon--1ulue";
export var noBullets = "index-module--noBullets--2yFhs";
export var border = "index-module--border--1QNBq";
export var promo = "index-module--promo--1dceX";